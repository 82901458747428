import React from "react";
import Layout from "../../../components/element/layout/Layout";
import routes from "../../../components/navigation/routes";
import { Redirect } from "@reach/router";

const SitesIndex = () => {
  return (
    <Layout>
      <Redirect noThrow to={routes.user.settings.to} />
    </Layout>
  );
};
export default SitesIndex;